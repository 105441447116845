import { Component, Input } from "@angular/core";
import { DispatchService } from "../../dispatchService";
import { ActivatedRoute } from "@angular/router";
import { WarpId } from '@wearewarp/universal-libs';
import TaskEntity from "../../entity/TaskEntity";
import RouteEntity from "../../entity/RouteEntity";
import { Const } from "@const/Const";
import { DeliveryInfo, Shipment, ShipmentItem } from "@wearewarp/types/data-model";
import { FormDataUpdateTaskStatus, ModalHelper, UpdateTaskStatus } from '@wearewarp/ng-antd'
import { Observable } from "rxjs";
import { MasterData } from "@services/master.data";
import { environment } from "@env/environment";
import { Utils } from "@services/utils";
import { BaseComponent } from "@abstract/BaseComponent";
import { DateUtil } from "@services/date-utils";
import { BizUtil } from "@services/biz";
import { DialogService } from "@dialogs/dialog.service";
import { RouteReturn } from "../init-return/task-return";
import { DrawerService } from "@app/drawers/drawer.service";
import { RoutePOD } from "../pod";
import ShipmentEntity from "../../entity/ShipmentEntity";
@Component({
  selector: 'dispatch-shipment-item',
  templateUrl: './index.html',
  styleUrls: [
    './index.scss',
  ]
})
export class DispatchShipmentItem extends BaseComponent {
  public isLoading = true;
  public displayInfo: any = {};
  protected route: RouteEntity;

  @Input() task: TaskEntity;
  @Input() isVisiblePOD: boolean = false;
  @Input() isEnableUpdateStatus: boolean = true;

  constructor(
    public activatedRoute: ActivatedRoute,
    private dispatchService: DispatchService,
    private modalHelper: ModalHelper
  ) {
    super(activatedRoute)
  }

  ngOnInit(): void {
    this.subscription.add(
      this.dispatchService.loading.subscribe(value => {
        this.isLoading = value;
      })
    )
  }

  ngOnChanges() {
    this.buildDisplayInfo();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe()
  }

  private async buildDisplayInfo() {
    if (!this.task) return;
    let shipment = this.task.getShipment();
    let taskType = this.task.getType();
    let deliveryInfo: DeliveryInfo;
    let locationType = '';
    // nếu stop đã chung pickup thì hiển thị pickInfo là ko cần thiết => hiển thị dropInfo
    if (taskType == Const.TaskType.PICKUP) {
      deliveryInfo = shipment.getDropInfo();
      locationType = 'Dropoff';
    } else if (taskType == Const.TaskType.DROPOFF) {
      deliveryInfo = shipment.getPickInfo();
      locationType = 'Pickup';
    }
    this.displayInfo = {
      shipment: shipment?.toJSON(),
      shipmentId : shipment.getId(),
      shipmentWarpId : WarpId.showShipment(shipment.getWarpId()),
      locationType: locationType,
      clientName: shipment.getClient()?.name,
      locationName: deliveryInfo?.locationName,
      addressText: MasterData.getAddressText(deliveryInfo?.addr),
      requiresAppointment: deliveryInfo?.requiresAppointment,
      appointmentInfo: deliveryInfo?.appointmentInfo,
      items: shipment.getItems().map((item, index) => ({
        name: this.getItemName(item, index),
        qtyNumer: item.qty || 0,
        qtyTxt: this.getItemQty(item),
        totalWeight: this.getItemTotalWeight(item),
        weightPerUnit: this.getItemWeightPerUnit(item),
        size: this.getItemSize(item),
      })),
      cssClassStatus: this.getTaskStatusCssClass(this.task?.toJSON()),
      status:  this.getTaskStatus(this.task?.toJSON()),
      trackingCode: shipment.getTrackingCode(),
      code: shipment.getCode(),
      trackingLink: this.getTrackingLink(shipment.getCode() ?? shipment.getTrackingCode()),
      totalPod: this.task.getTotalPod(),
      podNotConfirmed: this.task.getPodNotConfirmed(),
      failedReason: this.task.getFailedReason(),
      refNums: this.getRefNumsByTaskType(this.task),
    }
    if (deliveryInfo?.appointmentInfo?.from && deliveryInfo?.appointmentInfo?.from) {
      let time = deliveryInfo.appointmentInfo.from;
      let timeTo = deliveryInfo.appointmentInfo.to;
      this.displayInfo.scheduled = this.displayWindow({time, timeTo, timezone: deliveryInfo?.addr?.metadata?.timeZoneStandard})
    } else {
      let window = deliveryInfo?.windows?.[0];
      let time = window?.from;
      let timeTo = window?.to;
      this.displayInfo.scheduled = this.displayWindow({time, timeTo, timezone: deliveryInfo?.addr?.metadata?.timeZoneStandard})
    }

  }

  onBtnUpdateStatus() {
    if(!this.isEnableUpdateStatus) return;
    const tasks: any = [this.task?.toJSON()];
    const shipment = this.task.getShipment()?.toJSON();
    if (this.task.getStatus() == Const.TaskStatus.pickupFailed) return;
    const listDelayCodes = MasterData.getDelayCodes().map(item => ({
      value: item?._id,
      label: item?.name
    }))

    for(let index in tasks) {
      let task = tasks[index];
      if(task?.tonu) continue;
      let shipmentId = task.shipmentId;
      if(shipmentId !== shipment.id) continue;
      let serviceOptions = shipment?.cost?.serviceOptions || [];
      let serviceHaveTonu = serviceOptions.find(it => it._id === 'tonu');
      if(!serviceHaveTonu) continue;
      let data = {
        ...(tasks[index]['statusChangeLog'] || {}),
        canceled: {
          info: {
            customerTONU: serviceHaveTonu.total
          }
        }
      }
      tasks[index]['statusChangeLog'] = data
    }

    this.modalHelper.openForm<FormDataUpdateTaskStatus, UpdateTaskStatus>(UpdateTaskStatus, {
      nzTitle: 'Update Status',
      nzComponentParams: {
        delayCodeOptions: listDelayCodes,
        isAssignedCarrier: this.dispatchService.getRoute()?.isAssignedCarrier(),
        tasks: tasks,
        shipments: [shipment],
        onSave: (data) => {
          return new Observable(o => {
            this.dispatchService.updateTaskStatus({
              tasks,
              data: data
            }).then(() => {
              this.dispatchService.refresh();
              o.next();
            }).catch(e => {
              o.error(e)
            })
          }).subscribe();
        }
      }
    })
  }

  public getRouterLinkWarp(item: Shipment) {
    if (item.shipmentTransitType == Const.ShipmentTransitType.leg) {
      if (item.parentId) {
        // Nếu đã có parentId rồi thì cho đến đúng màn luôn
        return [Const.routeAdminOrderList, item.parentId, 'tracking-items'];
      } else {
        // Nếu chưa có parentId thì cho đi qua 1 màn trung gian để fetch dữ liệu trước rồi redirect sau
        return [Const.routeAdminOrderList, 'legs', item.id];
      }
    }
    if(item.orderId) {
      return [Const.routeAdminOrderList, item.orderId];
    }
    return [Const.routeAdminOrderList];
  }

  public getRouterLinkFragmentWarp(item: Shipment) {
    if (item.shipmentTransitType == Const.ShipmentTransitType.leg) {
      return undefined;
    }
    return item.id || (<any>item).shipmentId;
  }

  public copyTrackingCode() {
    let link = this.displayInfo.trackingLink;
    Utils.copyTextToClipboard(link, (e) => {
      if (e) {
        this.showErr("Cannot copy tracking link to clipboard");
      } else {
        this.showSuccess(
          "Tracking link has already been copied to the clipboard"
        );
      }
    });
  }
  public getTrackingLink(trackingCode) {
    return `${environment.trackingWebUrl}/${trackingCode}`;
  }

   // time, timeTo are ISO string 2022-03-29T18:19:10.000Z
   private displayWindow(obj: { time: string, timeTo: string, timezone: string }) {
    if (!obj) return 'N/A';
    let formatDateTime = Const.FORMAT_GUI_DATETIME_V3;
    let timeFrom = obj.time;
    let timeTo = obj.timeTo;
    if (!timeFrom || !timeTo) return 'N/A';
    timeFrom = DateUtil.displayLocalTime(timeFrom, { timezone: obj.timezone, format: formatDateTime });
    timeTo = DateUtil.displayLocalTime(timeTo, { timezone: obj.timezone, format: formatDateTime });
    let arr1 = timeFrom.split(',');
    let arr2 = timeTo.split(',');
    let isSameDay = arr1[0] == arr2[0];
    if (isSameDay) {
      timeTo = arr2[1]?.trim();
    }
    let str = `${timeFrom || 'N/A'} - ${timeTo || 'N/A'}`;
    let tzShort = DateUtil.timezoneStandardToUsShort(obj.timezone);
    if (tzShort) {
      str += ` ${tzShort}`;
    }
    return str;
  }

  public naText = 'N/A';
  getItemName(item: ShipmentItem, index: number): string {
    return item.name || ('Item ' + (index+1));
  }

  getItemQty(item: ShipmentItem): string {
    const quantity = item?.qty || this.naText
    const unit = item?.qtyUnit || this.naText
    return `${quantity} ${unit}`
  }

  getItemSize(item: ShipmentItem): string {
    return BizUtil.getItemSizeDesc(item);
  }

  getItemTotalWeight(item: ShipmentItem): string {
    const quantity = item?.qty || 1;
    const weight = item?.weightPerUnit ?? 0;
    const unit = item?.weightUnit ?? '';
    return `${Utils.roundNumber(weight * quantity, 0)} ${unit}`
  }

  getItemWeightPerUnit(item: ShipmentItem): string {
    const weight = item?.weightPerUnit ?? 0;
    const unit = item?.weightUnit ?? '';
    return `${weight} ${unit}`
  }

  private getRefNumsPickInfo(shipment: ShipmentEntity): string[] {
    if(!shipment) return [];
    const refNums = shipment?.getPickInfo()?.refNums;
    return Utils.uniqElementsArray(refNums) || [];
  }

  private getRefNumsDropInfo(shipment: ShipmentEntity): string[] {
    if(!shipment) return [];
    let refNums = shipment?.getDropInfo()?.refNums;
    return Utils.uniqElementsArray(refNums) || [];
  }

  private getRefNumsByTaskType(task: TaskEntity): string[]{
    if(!task) return [];
    const taskType = task.getType();
    const shipment = task.getShipment();
    if(taskType == Const.TaskType.PICKUP) {
      return this.getRefNumsPickInfo(shipment);
    }
    return this.getRefNumsDropInfo(shipment);
  }

  public shouldInitiateReturn() {
    if (this.task.getType() != Const.TaskType.DROPOFF) {
      return false;
    }
    // nếu đã tồn tại returntask thi ko tạo nữa
    if (this.task.isExistsReturn()) {
      return false;
    }
    return this.task.getStatus() == Const.TaskStatus.failed;
  }

  public get isShowFailedReason() {
    return this.task.getStatus() == Const.TaskStatus.failed;
  }

  public onBtnInitReturn() {
    if (!this.shouldInitiateReturn()) return;
    DialogService.openDialog(RouteReturn, {
      nzComponentParams: {
        jobId: this.dispatchService?.getRoute()?.getId(),
        taskIds: [this.task.getId()],
        shipmentWarpIds: [this.task.getShipment().getWarpId()],
        onSave: data => this.createReturnTask(data),
        onSubmitSucceeded: resp => {
          this.dispatchService.refresh();
        }
      },
      nzClassName: "modal-no-padding modal-task-return",
      nzCentered: true,
      nzTitle: null,
      nzClosable: false,
    });
  }

  private createReturnTask(data: any) {
    const taskIds = [this.task.getId()];
    let url = Const.APIV2(`${Const.APIURI_TASKS}/batchUpdate`);
    return this.api.POST(url, {
      action: 'initiateReturn',
      data: taskIds.map(taskId => ({
        id: taskId,
        data: data
      }))
    });
  }

  public onBtnAddPod() {
    let taskIds = [this.task.getId()];
    DrawerService.openDrawer1(RoutePOD, {
      nzContentParams: {
        taskIds: taskIds,
        jobId: this.dispatchService.getRoute()?.getId(),
        openUploadForm: true,
        refreshData: data => {
          this.dispatchService.refresh();
        }
      },
      nzWidth: 400,
      nzWrapClassName: 'wrap-drawer route-pod-drawer',
    });
  }

  public onBtnViewPod() {
    let taskIds = [this.task.getId()];
    DrawerService.openDrawer1(RoutePOD, {
      nzContentParams: {
        taskIds: taskIds,
        jobId: this.dispatchService.getRoute()?.getId(),
        refreshData: data => {
          this.dispatchService.refresh();
        }
      },
      nzWidth: 400,
      nzWrapClassName: 'wrap-drawer route-pod-drawer',
    });
  }

  isShowRef(): boolean{
    return this.displayInfo.refNums.length > 0;
  }
}
