import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";


@Component({
  selector: "route-update-pod-tasks",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class RouteUpdatePodTasks extends BaseComponent {
  private _podItem: any;

  @Input() refreshData: Function;
  @Input() tasks: any[];
  @Input() set podItem(value) {
    this._podItem = value;
    this.initChecked();
  }
  get podItem() {
    return this._podItem;
  }

  isSubmitting: boolean = false;
  checkedAllPods: boolean;
  indeterminatePods: boolean;
  public selecteds: { [key: string]: boolean } = {};

  private initChecked() {
    this.selecteds = {};
    this.tasks.forEach(it => {
      this.selecteds[it.id] = this.podItem?.tasks?.findIndex(task => task.id === it.id) != -1 ? true : false;
    });
    this.refreshCheckAllPods();
  }
  public refreshCheckAllPods() {
    this.checkedAllPods = this.tasks.every(it => this.selecteds[it.id]);
    this.indeterminatePods = this.tasks.some(it => this.selecteds[it.id]) && !this.checkedAllPods;
  }

  public onCheckAllPods(checked: boolean) {
    this.tasks.forEach(it => this.selecteds[it.id] = checked);
    this.refreshCheckAllPods();
  }

  public getTask(taskType) {
    return this.tasks.filter(it => it.type === taskType).sort((a, b) => a?.shipment?.warpId - b?.shipment?.warpId);
  }

  public getBtnText(){
     if(!this.indeterminatePods && !this.checkedAllPods) return 'Delete POD'
      return 'Update POD'
  }

  public async onSubmit() {
    if (Object.values(this.selecteds).every(it => !it)) {
      this.confirmYesNo("You have unselected all tasks. The POD will be deleted. do you want to continue?", async () => {
        await this.updatePodTask();
      });
      return;
    }

    await this.updatePodTask();
  }

  public async updatePodTask() {
    this.isSubmitting = true;
    const jobId = this.tasks?.[0]?.job_id;
    // console.log(jobId, this.podItem, this.tasks, this.selecteds)
    delete this.podItem.loadingTask; //prevent error Converting circular structure to JSON
    await this.api.POST(`${Const.APIV2(Const.APIURI_JOBS)}/${jobId}/update-pod-tasks`, {
      pod: this.podItem,
      tasks: this.tasks.map(it => ({ id: it.id, selected: this.selecteds[it.id] }))
    }).toPromise();

    this.isSubmitting = false;
    this.refreshData();
    this.showSuccess("Update POD tasks successfully");
  }
}