import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { BaseComponent } from "@abstract/BaseComponent";
import { WarpId } from "@wearewarp/universal-libs";
import { Const } from "@const/Const";
import TaskEntity from "../../entity/TaskEntity";
import { Utils } from "@services/utils";

@Component({
  selector: 'display-shipment-warpid',
  templateUrl: './index.html',
  styleUrls: [
    './index.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayShipmentWarpID extends BaseComponent {
  public displayInfo: any = {};
  public isExpanded: boolean = false;

  @Input() tasks: TaskEntity[] = [];

  ngOnChanges() {

  }

  getRouterLink(item) {
    item = item?.getShipment()?.toJSON()
    if (item?.shipmentTransitType == Const.ShipmentTransitType.leg) {
      if (item?.parentId) {
        // Nếu đã có parentId rồi thì cho đến đúng màn luôn
        return [Const.routeAdminOrderList, item.parentId, 'tracking-items'];
      } else {
        // Nếu chưa có parentId thì cho đi qua 1 màn trung gian để fetch dữ liệu trước rồi redirect sau
        return [Const.routeAdminOrderList, 'legs', item.id];
      }
    }
    if (item?.orderId) {
      return [Const.routeAdminOrderList, item.orderId];
    }
    return [Const.routeAdminOrderList];
  }

  public getWarpIdText(task: TaskEntity) {
    return WarpId.showShipment(task.getShipment()?.getWarpId());
  }

  public getDisplayTasks(tasks) {
    if (!Utils.isArray(tasks)) return [];
    if(this.isExpanded) return tasks;
    let data = [...tasks];
    let newArray = data.slice(0, 10);
    return newArray;
  }

  public getListIds(items: TaskEntity[]) {
    const ids = [];
    items.forEach((it, index) => {
      if (index > 9) {
        ids.push(this.getWarpIdText(it));
      }
    });
    return ids.join(', ');
  }

  public getColor(shipment) {
    const status = shipment.getStatus();
    switch (status) {
      case Const.TaskStatus.created:
        return "grey"
      case Const.TaskStatus.enroute:
        return "blue"
      case Const.TaskStatus.arrived:
        return "yellow"
      case Const.TaskStatus.succeeded:
        return "green"
      default:
        return "red"
    }
  }

  toggleExpand() {
    this.isExpanded = !this.isExpanded;
  }

  copy() {
    let text = this.tasks.map(task => this.getWarpIdText(task)).join(', ');
    if(!text) return;
    Utils.copyTextToClipboard(text, e => {
      if (e) {
        this.showErr('Cannot copy text to clipboard');
      } else {
        this.showSuccess('ShipmentIds has already been copied to the clipboard');
      }
    })
  }
}