<div class="form-header">
  <div style="align-self: center;">
    <i class="ic-right-close clickable" nz-icon nzType="close" nzTheme="outline" (click)="closeDrawer()"></i>
    <span class="form-title-main">Add POD</span>
  </div>
  <button nz-button (click)="closeDrawer()" class="right15" style="align-self: center;">Close</button>
</div>
<div class="form-body">
  <div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
  <div *ngIf="isError" class="nodata">Cannot fetch data, please try again later</div>

  <div class="task-pod" *ngIf="!isLoading && !isError">
    <div class="pod-header">
      <button nz-button nzType="default" (click)="inputFile.click()" [nzLoading]="isUploading" style="width: 100%">
        <i nz-icon nzType="plus" nzTheme="outline"></i>
        Add POD
      </button>
      <input #inputFile type="file" hidden accept=".pdf,.png,.jpg,.jpeg" (change)="onFileSelected(inputFile)"
        id="uploadRoutePODInput">
    </div>
    <div class="pod-body">
      <div class="pod-item" *ngFor="let item of items;let i = index">
        <div class="image">
          <ng-container *ngIf="images.length">
            <div ngxViewer [viewerOptions]="{ transition: true }">
              <img *ngFor="let it of images" class="attached-pod" [ngClass]="{ 'active': it._id === item._id }"
                width="100px" [src]="it.url" alt="" />
            </div>
          </ng-container>
          <a (click)="viewPodItem(item, i)" *ngIf="item.isPdf">
            <div class="attached-pod pdf overlay-container">
              <canvas id="task-{{firstTaskId}}-pod-{{i}}" width="100px"></canvas>
            </div>
          </a>
        </div>
        <div class="info">
          <div class="info-text">
            <div class="index">{{i+1}}</div>
            <div>Uploaded by 
              <warp-when-by [model]="item?.insert" [viewTemplate]="tplPODCreateWhenBy"></warp-when-by>
            </div>
            <div class="date">{{formatDate(item.createdAt)}}</div>
            <div class="type">
              <nz-tag
                [nzColor]="item.podType === 'Photo' || !item.podType ? 'blue' : item.podType === 'BOL' ? 'orange' : 'default'">{{item.podType
                || "Photo"}}
              </nz-tag>
              <nz-tag nzColor="red" *ngIf="getDuplicated(i)">
                Similar with #{{getDuplicated(i)?.index+1}} ({{(getDuplicated(i).value * 100).toFixed(2) }}%)
              </nz-tag>
            </div>
            <div class="appled-tasks" [nz-popover] nzPopoverTrigger="click" nzPopoverTitle="Tasks" [nzPopoverContent]="contentTemplate"
              nzPopoverPlacement="left">
              Applied for {{item.countTasks}} tasks <span nz-icon nzType="edit" nzTheme="outline"></span>
              <ng-template #contentTemplate>
                <route-update-pod-tasks [tasks]="this.job?.tasks || []" [podItem]="item" [refreshData]="refreshPodChange"></route-update-pod-tasks>
              </ng-template>
            </div>
          </div>
          <div class="confirmed" *ngIf="isConfirmPOD(item)">
            <nz-tag nzColor="success">

              <i nz-icon nzType="check-circle" nzTheme="outline"></i>
              <span class="text">Confirmed</span>
            </nz-tag>
            <button nz-button nzType="dashed" nzDanger (click)="onBtnUnConfirm(item)" nzSize="small" nz-tooltip nzTooltipTitle="Un-confirm">
              <span nz-icon nzType="rollback" nzTheme="outline"></span>
            </button>
          </div>
          <div class="action">
            <div class="confirm"  *ngIf="!isConfirmPOD(item)">
              <button nz-button nzType="primary" (click)="onBtnConfirm(item)"
               >Confirm</button>
              
            </div>
            <div class="delete">
              <nz-space>
                <button (click)="openNoteIsuse(item)" class="center-vertical" style="border: none;" nz-tooltip nzTooltipTitle="POD Isuse" nz-button nzType="default">
                  <span style="margin-top: 4px;" class="f20" nz-icon nzType="info-circle" nzTheme="outline"></span>
                </button>
                <button *nzSpaceItem nz-button nzType="default" (click)="onBtnDownload(item)"
                  [nzLoading]="item?.isDownloading" style="border: none">
                  <img src="/assets/img/dispatch-icons/sim_card_download.svg" />
                </button>
                <button *nzSpaceItem nz-button nzType="default" (click)="onBtnDelete(item)" style="border: none">
                  <img src="/assets/img/dispatch-icons/delete.svg" />
                </button>
              </nz-space>
            </div>
          </div>
          <div *ngIf="isConfirmPOD(item)" class="confirmed-info">
            <warp-when-by [model]="item?.podConfirmed" [viewTemplate]="tplPODConfirmedWhenBy"></warp-when-by>
              {{formatDate(item?.podConfirmed?.when)}}
          </div>
          
        </div>
      </div>
    </div>
  </div>
</div>

<pod-issue-popup class="pod-issue-component" [podItem]="podIssueItem" [ngClass]="{'active': podIssueShowing}" (onClose)="onCloseNoteIsuseDrawer()"></pod-issue-popup>

<ng-template #tplPODCreateWhenBy let-hasData="hasData" let-displayInfo="displayInfo">
  <span *ngIf="hasData" class="medium">
    <a [routerLink]="[displayInfo.hyperLinkCreatedBy]" 
      style="color: #000000;"
      target="_blank">
      {{displayInfo.createdBy}}
    </a>
  </span>
</ng-template>
<ng-template #tplPODConfirmedWhenBy let-hasData="hasData" let-displayInfo="displayInfo">
  <span *ngIf="hasData">
    <a [routerLink]="[displayInfo.hyperLinkCreatedBy]" 
      style="color: #334155;"
      target="_blank">
      {{displayInfo.createdBy}}
    </a>
  </span>
</ng-template>